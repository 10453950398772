<template>
<footer>
    <div class="container">
        <div class="row">
            <div class="foot-box">
                <p class="title">友情链接</p>
                <ul class="list">
                    <li class="cell"><a href="http://www.rerc.com.cn/" target="_blank">自然资源部不动产登记中心</a></li>
                    <li class="cell"><a href="http://www.mnr.gov.cn/" target="_blank">中华人民共和国自然资源部</a></li>
                    <li class="cell"><a href="http://www.mohurd.gov.cn/" target="_blank">中华人民共和国住房和城乡建设部</a></li>
                    <li class="cell"><a href="http://www.gov.cn/" target="_blank">中央人民政府门户网站</a></li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="foot-box">
                <p class="title">联系我们</p>
                <ul class="list">
                    <li class="cell">联系邮箱：cshr@css110.com</li>
                    <li class="cell">联系电话：028-84166295 15600518256</li>
                    <li class="cell">联系地址：中国（四川）自由贸易试验区成都高新区天府三街69号1栋25层2502号</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="qr-box">
                <div class="qr-item" v-for="(item,idx) in companyList" :key="idx">
                    <el-image :src="item.img" fit="cover" :preview-src-list="[item.img]"></el-image>
                    <h6>{{item.name}}</h6>
                    <h6>{{item.title}}</h6>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright">
        <p>
            <span> 成都传晟信息技术有限公司 版权所有 2010-2020 保留所有权利，备案号：蜀ICP备12009152号-2</span>
            <a class="record" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002002911"> <img src="/images/common/guohui.png">川公网安备 51019002002911号</a>
        </p>
    </div>
</footer>
</template>

<script>
export default {
    data() {
        return {
            companyList: [{
                    id: 0,
                    title: '微信公众号',
                    name: '成都云产科技有限公司',
                    img: '/images/common/ycgzh.jpg'
                },
                {
                    id: 1,
                    title: '微信公众号',
                    name: '成都传晟信息技术有限公司',
                    img: '/images/common/gzh.jpg'
                },
                {
                    id: 2,
                    title: '订阅号',
                    name: '成都传晟信息技术有限公司',
                    img: '/images/common/dyh.jpg'
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
footer {
    color: #fff;
    font-size: 14px;
    text-align: center;
    padding: 30px 0px 20px 0px;
    background-color: #2f2f2f;

    .container {
        margin: auto;
        display: flex;
        max-width: 1200px;
        align-items: flex-start;
        justify-content: space-between;

        .row {
            flex: 1;
            margin-left: 24px;

            .foot-box {
                flex: 1;
                display: flex;
                flex-direction: column;

                .title {
                    margin: 0;
                    font-size: 18px;
                    text-align: left;
                    font-weight: bold;
                }

                .list {
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .cell {
                        flex: 1;
                        padding: 5px 0;
                        text-align: left;
                        word-break: break-all;

                        a {
                            color: #fff;
                        }
                    }
                }
            }

            .qr-box {
                flex: 1;
                display: flex;
                justify-content: space-between;

                .qr-item {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    .el-image {
                        width: 96px;
                        height: 96px;
                        display: inline-block;
                    }

                    h6 {
                        margin: 6px 0;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .copyright {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            display: flex;
            align-items: center;

            .record {
                display: flex;
                color: #409eff;
                align-items: center;
            }

            span {
                margin-right: 12px;
            }
        }
    }
}

@media (max-width: 576px) {
    footer {
        font-size: 12px;

        .container {
            max-width: 100%;
            flex-direction: column;

            .row {
                width: 100%;
                margin-left: 0;
                padding: 0 14px;
                box-sizing: border-box;

                .qr-box {
                    flex: 1;
                    display: flex;
                    justify-content: space-between;

                    .qr-item {
                        flex: auto;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;

                        .el-image {
                            width: 66px;
                            height: 66px;
                            display: inline-block;
                        }

                        h6 {
                            margin: 6px 0;
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .copyright {
            width: 100%;
            padding: 0 12px;
            margin-top: 24px;
            box-sizing: border-box;

            p {
                margin: 0;
                display: flex;
                align-items: center;
                flex-direction: column;

                span {
                    margin-right: 0px;
                    margin-bottom: 12px;
                }
            }
        }
    }
}
</style>
