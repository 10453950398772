<template>
<router-view></router-view>
</template>

<script>
export default {
    name: "App",
};
</script>

<style>
body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Microsoft YaHei UI';
}

div,
span,
h2,
h3 {
    user-select: none;
    font-family: 'Microsoft YaHei UI';
}

ul {
    list-style: none;
}

a {
    font-style: normal;
    text-decoration: none;
}

</style>
