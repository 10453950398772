import { routes } from './config';
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
    routes,
    history: createWebHistory(),
}) 

router.beforeEach((to, from, next) => {
    const title = to.meta.title;
    document.title = title;
    next();
})


export default router;