const debounce = function (func, delay=5000,immediate=false) {
    let timer = null;
    return (...args) => {
        if (timer) clearTimeout(timer);
        if (immediate && !timer) func.apply(this, args);
        timer = setTimeout(() => { func.apply(this, args) }, delay);
    }
};

const throttle = function (func,delay=5000){
    let previous = 0,timer = null;
    return (...args)=> {
    let now = +new Date()
    if (now - previous > delay) {
      previous = now;
      func.apply(this, args);
    }else{
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
            previous = now;
            func.apply(this, args);
        }, delay)
    }
  }
}
export {
    debounce,
    throttle
};