<template>
<div class="custom-title">
    <img class="logo" src="/images/common/i6.png" alt="标识">
    <span class="name">{{name}}</span>
    <img class="logo" src="/images/common/i8.png" alt="标识">
</div>
</template>

<script>
export default {
    props: ['name'],
}
</script>

<style lang="scss" scoped>
.custom-title {
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
        width: 52px;
        height: 36px;
    }

    .name {
        font-size: 32px;
        padding: 0 12px;
        color: #333333;
        font-weight: bold;
        letter-spacing: 3px;
    }
}

@media (max-width: 576px) {
    .custom-title {

        .logo {
            width: 38px;
            height: 22px;
        }

        .name {
            font-size: 20px;
            padding: 0 12px;
            color: #333333;
            font-weight: bold;
            letter-spacing: 1px;
        }
    }
}
</style>
