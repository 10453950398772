import Qs from 'qs'
import axios from 'axios'
import { ElMessage } from 'element-plus';


// 配置全局接口

if (process.env.NODE_ENV == 'production') {
    axios.get('/server.api.json').then(res => {
        axios.defaults.baseURL = res.data.baseUrl;
    })
} else {
    axios.defaults.baseURL = '/api'
}
axios.defaults.timeout = 10 * 60 * 1000;// 请求超时时间
axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";
axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.transformRequest = [function (data, config) {
    if (!config['Content-Type']) return Qs.stringify(data);
    switch (config['Content-Type'].toLowerCase()) {
        case 'application/json;charset=utf-8': {
            return JSON.stringify(data)
        }
        // 上传类型请求,返回原生对象
        case 'multipart/form-data;charset=utf-8': {
            return data
        }
        default: {
            return Qs.stringify(data);
        }
    };
}];

// 请求拦截器
axios.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.error(error);
    });

// 响应拦截器
axios.interceptors.response.use(
    response => {
        return Promise.resolve(response);
    },
    // 服务器状态码不是200的情况
    error => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    ElMessage.warning({
                        type: 'warning',
                        message: '登录已过期,请重新登录',
                    });
                    break;
                case 403:
                    ElMessage.warning({
                        type: 'warning',
                        message: '登录已过期,请重新登录',
                    });
                    break;
                case 404:
                    ElMessage.warning({
                        type: 'warning',
                        message: '接口不存在',
                    });
                    break;
                case 500:
                    ElMessage.warning({
                        type: 'warning',
                        message: '服务器内部错误',
                    });
                    break;
                case 502:
                    ElMessage.warning({
                        type: 'warning',
                        message: '502 Bad Gateway',
                    });
                    break;
            }
        }
        return Promise.reject(error);
    }
);

const httpGet = (url, params) => {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params
        })
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err)
            })
    });
}

const httpPost = (url, params) => {
    let config = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        },
    };
    return new Promise((resolve, reject) => {
        axios.post(url, params, config)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err)
            })
    });
}
// 文件上传
const upLoadFile = (url, params) => {
    let config = {
        headers: {
            "Content-Type": "multipart/form-data;charset=UTF-8"
        }
    };
    return new Promise((resolve, reject) => {
        axios.post(url, params, config)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err)
            })
    });
}
export {
    axios,
    httpGet,
    httpPost,
    upLoadFile
}