import App from './App.vue';
import router from './router';
import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import customTitle from './components/custom-title/index.vue';
import customFooter from './components/custom-footer/index.vue';
import { axios, httpPost, httpGet, upLoadFile } from './http/axios';
import { base64toFile, imgToBase64, fileToBlob } from './lib/img-conversion.js';
import { debounce,throttle } from './lib/utils.js';

const app = createApp(App); 

// 设置全局变量
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$httpGet = httpGet;
app.config.globalProperties.$httpPost = httpPost;
app.config.globalProperties.fileToBlob = fileToBlob;
app.config.globalProperties.$upLoadFile = upLoadFile;
app.config.globalProperties.imgToBase64 = imgToBase64;
app.config.globalProperties.base64toFile = base64toFile;
app.config.globalProperties.debounce = debounce;
app.config.globalProperties.throttle = throttle;
app.config.globalProperties.baseUrl = 'http://ai.chinabdc.com.cn/';

// 全局组件
app.component('customTitle', customTitle);
app.component('customFooter', customFooter);

app.use(router)
app.use(ElementPlus)



app.mount('#app')

